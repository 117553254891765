<template>

</template>

<script>
var rldata = {
  user: null,
}

import router from "@/router"

export default {
  name: "Landing_Page",
  data () {
    return {
      rldata
    }
  },
  mounted () {
    try{
        this.$root.getUserData(this.rldata);
      } catch(err){
        this.$root.logout();
      }
  },
   watch: {
     "rldata.user": function(handler){
       if(handler != null) {
            router.push('/dashboard')
       } else {
            router.push('/login')
       }
     }
   }
}
</script>
